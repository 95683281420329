import httpClient from "./http.api";

const api = {
    getVoice(text) {
        return httpClient.get('voice-synthesizer/synthesize', {
            params: {
                text
            }
        })
    },
};

export default api;
