<template>
  <div class="information-table" style="position: fixed; width: 100%; height: 100%; background: #fff">
    <b-overlay :show="loading">
      <b-col cols="12" class="d-flex">
          <b-table striped :items="data" :fields="fields" :small="true">
            <template #cell(startTime)="data">
              <span style="font-size: 50px">{{time(data.item.startTime)}}</span>
            </template>
            <template #cell(route)="data">
              <span style="font-size: 50px"><b>{{data.item.route.number}}</b> {{data.item.route.name}}</span>
            </template>
            <template #cell(gosNumber)="data">
              <span><b style="font-size: 50px">{{data.item.bus.gosNumber}}</b></span>
            </template>
            <template #cell(platform)="data">
              <b style="font-size: 50px" v-if="data.item.status === 'landing'">{{data.item.platform}}</b>
            </template>
            <template #cell(status)="data">
              <div style="font-size: 30px;">
<!--                <div v-if="data.item.status === 'planned'">-->
<!--                  <b style="padding: 3px 6px; color: #d0bd2d;">ОЖИДАЕТ ПОСАДКИ</b>-->
<!--                </div>-->
                <div v-if="data.item.status === 'landing'">
                  <b style="padding: 3px 6px; color: #5fad49;">ПОСАДКА</b>
                </div>
                <div v-if="data.item.status === 'in_trip'">
                  <b style="padding: 3px 6px; color: #0f4ec5;">В ПУТИ</b>
                </div>
              </div>
            </template>
          </b-table>
      </b-col>
    </b-overlay>
  </div>
</template>

<script>
import TripsApi from "@/api/trips.api";
import moment from "moment";
import VoiceSynthesizerApi from "@/api/voiceSynthesizer.api";
import PromotionApi from "@/api/promotion.api";

const playVoice = function (path) {
  return new Promise(function (resolve) {
    const audio = new Audio(path);
    audio.play()
    audio.addEventListener('ended', () => {
      resolve();
    })
  });
}

export default {
  name: "SchedulesAndVehicles",
  data() {
    return {
      voiceQueue: [],
      periodicVoiceIsActive: false,
      startLoading: {
        tripId: null,
        platform: '',
      },
      notificationQueue: [],
      data: [],
      errors: {},
      loading: false,
      fields: [
        {
          key: 'startTime',
          label: 'Отправление',
          formatter: value => moment(value).format('HH:mm')
        },
        {
          key: 'route',
          label: 'Маршрут'
        },
        {
          key: 'gosNumber',
          label: 'Гос.номер'
        },
        {
          key: 'platform',
          label: 'Платформа'
        },
        {
          key: 'status',
          label: 'Статус'
        }
      ]
    }
  },
  mounted() {
    this.loadTrips(false);
    setInterval(() => {
      this.loadTrips();
    }, 1000 * 10);
    setInterval(() => {
      this.voiceAllTrips();
    }, 1000 * 60 * 10);
  },
  methods: {
/*    async startQueue() {
      let voiceQueue = this.voiceQueue;
      const timer = function () {
        return new Promise(function(resolve) {
          setTimeout(async () => {
            let func = voiceQueue.pop()
            if (func) {
              await func()
            }
            resolve()
            console.log('timer')
          }, 100)
        })
      }

      // eslint-disable-next-line no-constant-condition
      while(true) {
        await timer()
        console.log('timer')
      }
    },*/
    async voiceAllTrips() {
      let promotions = await PromotionApi.getAll().then((response) => {
        return response.data.data
      });
      console.log(promotions)

      this.periodicVoiceIsActive = true;
      const trips = this.data.filter(trip => trip.status === 'landing');
      let text = '';

      if (trips.length) {
        text = 'Внимание! Выполняется посадка на рейс' + (trips.length === 1 ? '' : 'ы') + ': ' + trips.map(trip => 'маршрут ' + trip.route.number + ' ' + trip.route.name + ' - платформа ' + trip.platform).join(', ');
      }

      promotions = promotions.filter(promotion => promotion.isActive)

      if (promotions.length > 0) {
        text += '. Реклама! ' + promotions.map(promotion => promotion.promotion).join('. ')
      }

      text += '. Уважаемые пассажиры! Убедительная просьба! Проявлять бдительность! Обращать внимание на бесхозные предметы и вещи, длительное время находящиеся без присмотра, а также людей с подозрительным поведением!'

      console.log(text)
      await VoiceSynthesizerApi.getVoice(text).then(async response => {
        await playVoice(require('@/assets/audio/bell.mp3'));
        await playVoice(response.data.voice);
      })

      this.periodicVoiceIsActive = false;
    },
    time(startTime) {
      return moment(startTime).format('HH:mm')
    },
    voice(text) {
      VoiceSynthesizerApi.getVoice(text).then(async response => {
        await playVoice(require('@/assets/audio/bell.mp3'));
        await playVoice(response.data.voice);
      })
    },
    loadTrips(withNotification = true) {
      TripsApi.getCurrentTrips().then(response => {
        if (withNotification) {
          response.data.forEach(trip => {
            if (this.data.find(dataTrip => dataTrip.id === trip.id && dataTrip.status === 'planned') && trip.status === 'landing' && !this.periodicVoiceIsActive) {
              this.voice('Внимание! объявляется посадка на рейс ' + trip.route.number + ' на платформе ' + trip.platform)
            }
          })
        }

        this.data = response.data
      })
    }
  }
}
</script>

<style scoped>
  .information-table div div table thead th div{
    font-size: 40px;
  }
</style>